import axios from 'axios'

export default {
    namespaced: true,
    state: {
        categorias: [],
        perfil: '',
        saveStatus: '',
    },

    mutations: {
        setCategorias(state, payload) {
            state.categorias = payload;
        },

        setPerfil(state, payload) {
            state.perfil = payload;
        },

        setSaveStatus(state, payload) {
            state.saveStatus = payload;
        },
    },

    actions: {
        async actionObtenerCategorias({ commit }, data) {
            const { perfil_id } = data;
            const api_server = await axios.get(`/registro-formulario/obtener-categoria/${perfil_id}`);
            commit('setCategorias', api_server.data.data);
        },

        async actionObtenerPerfil({ commit }, data) {
            const { perfil_id } = data;
            const api_server = await axios.get(`/registro-formulario/obtener-perfil/${perfil_id}`);
            commit('setPerfil', api_server.data.data.perfil);
        },

        async actionSaveCategoriasFormularios({ commit }, data) {
            const api_server = await axios.post(`/registro-formulario/save`, data);
            commit('setSaveStatus', api_server.data);
        },
    },
    getters: {
        gettColegios(state) {
            const { colegios } = state.categorias;
            return colegios;
        },

        gettCursos(state) {
            const { cursos } = state.categorias;
            return cursos;
        },

        gettLetrasCursos(state) {
            const { letras_cursos } = state.categorias;
            return letras_cursos;
        },

        gettCargos(state) {
            const { cargos } = state.categorias;
            return cargos;
        },

        gettNiveles(state) {
            const { niveles } = state.categorias;
            return niveles;
        },

        gettNombrePerfil(state) {
            const { nombre } = state.perfil;
            return nombre;
        },

        getGeneros(state) {
            const { generos } = state.categorias;
            return generos;
        },
    },
}