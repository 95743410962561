import axios from 'axios'
import Auth from '../Auth/Auth';

export default {
    namespaced: true,
    state: {
        catalogs: [],
    },

    mutations: {
      setCatalogs(state, paylaod) {
        state.catalogs = paylaod;
      }
    },

  actions: {
    async actionObtenerCatalogs({ commit }) {
      const api_server = await axios.get(`/administrador/obtener-filtros-concentrados`, Auth.state.header);
      commit('setCatalogs', api_server.data.data);
    },
  },    
  getters: {
  }
}