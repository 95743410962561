import Vue from 'vue'
import VueRouter from 'vue-router'
import { getDeviceType } from '../utils/deviceDetect';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/Home.vue')
  },
  {
    path: '/registro/:perfil_id',
    name: 'formulario_registro',
    component: () => import('@/views/formularioRegistro/formularioRegistroView.vue')
  },
  {
    path: '/iniciar-cuestionario',
    name: 'iniciar-cuestionario',
    component: () => import('@/views/inicio-cuestionario/inicioCuestionario.vue')
  },
  {
    path: '/encuesta/:encuenta_id/:pagina',
    name: 'encuesta',
    component: () => import('@/views/encuesta/encuestaView.vue'),
    meta: { requiresDesktop: true }
  },
  {
    path: '/encuesta-mobile/:encuenta_id/:pagina',
    name: 'encuesta-mobile',
    component: () => import('@/views/encuesta/encuestaMobile.vue'),
    meta: { requiresMobile: true }
  },
  {
    path: '/encuesta-pregunta-libre/:encuenta_id',
    name: 'encuesta_pregunta_libre',
    component: () => import('@/views/encuestaPreguntaLibre/encuestaPreguntaLibreView.vue'),
    meta: { requiresMobile: false }
  },
  {
    path: '/ad/',
    name: 'admin-login',
    component: () => import('@/views/admin/admin-login.vue'),
  },
  {
    path: '/admin/:id?/',
    name: 'admin-main',
    meta: { requiresAuth: true },
    component: () => import('../views/admin/admin-main/admin-main.vue'),
    children: [
      {
        path: 'back-office',
        name: 'BackOffice',
        meta: { requiresAuth: true },
        component: () => import('../views/admin/admin-back-office/admin-back-office.vue'),
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  const deviceType = getDeviceType();
  
  if (deviceType === 'desktop' && to.meta.requiresMobile) {
    if (to.name !== 'encuesta') {
      next({ name: 'encuesta', params: to.params });
    } else {
      next();
    }
  } else if ((deviceType === 'mobile' || deviceType === 'tablet') && to.meta.requiresDesktop) {
    if (to.name !== 'encuesta-mobile') {
      next({ name: 'encuesta-mobile', params: to.params });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
