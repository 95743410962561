import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from "vue-axios"
import VueSweetalert2 from 'vue-sweetalert2';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueNotification from '@mathieustan/vue-notification';

Vue.config.productionTip = false


import './assets/scss/app.scss'
import './assets/css/style.css'
import 'sweetalert2/dist/sweetalert2.min.css';

//axios.defaults.baseURL = "http://localhost:8080";
axios.defaults.baseURL = "https://" + window.location.host;


Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(axios)
Vue.use(VueNotification)
Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2)
//Vue.use(VueReCaptcha, { siteKey: '6LfR8l8oAAAAAE8C45Ng1TfwYxbIxvOCMgH9TUSh' })


window.addEventListener('popstate', function (event) {
  window.history.forward();
});

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    // Evitar que el usuario retroceda a la página anterior
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = function (event) {
      window.history.pushState(null, '', window.location.href);
    };
  }
}).$mount('#app')
