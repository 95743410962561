import axios from 'axios'
import { jwtDecode } from "jwt-decode";

export default {
  namespaced: true,
  state: {
    auth: {},
    auth_token: '',
    header: {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'content-type': 'application/json',
        'access-control-allow-origin': '*',
        'psx-domain': 'CHI1',
        'x-token': localStorage.getItem('user-session') || sessionStorage.getItem('user-session')
      }
    }
  },
  mutations: {
    setAuth(state, payload) {
      
      state.auth_token = payload
      state.header.headers['x-token'] = payload
      state.auth = (payload === '') ? '' : jwtDecode(payload)
    },
    setAuthDomain(state, payload) {
      state.header.headers['psx-domain'] = payload;
    }
  },
  actions: {
    auth({ commit }, data) {
      try {
        if (data.remember) {
          localStorage.setItem('user-session', data.data["x-token"]);
          commit('setAuth', data.data["x-token"]);
        }
        else {
          sessionStorage.setItem('user-session', data.data["x-token"]);
          commit('setAuth', data.data["x-token"]);
        }
        
      } catch (error) {
        throw error;
      }
    },
    /**
     * @description accion que agrega el dominio a los header 
     * @param {commit} param0 
     * @param {String} domain 
     */
    authDomain({ commit }, domain) {
      if (localStorage.getItem('domain') || sessionStorage.getItem('domain')) {
        commit('setAuthDomain', localStorage.getItem('domain') || sessionStorage.getItem('domain'));
      }
      else {
        commit('setAuthDomain', domain);
      }
    },
    /**
     * @description accion que verifica si hay un usuario activo en el navegador
     * @param {*} param0 
     */
    authVerify({ commit }) {
      if (localStorage.getItem('user-session')) {
        commit('setAuth', localStorage.getItem('user-session'));
      }
      else if (sessionStorage.getItem('user-session')) {
        commit('setAuth', sessionStorage.getItem('user-session'));
      }
      else {
        commit('setAuth', ''); 
      }
    },
    /**
     * @description accion valida el token del registro de usuario
     * @param {*} param0 
     */
     async verificarToken({ commit, state }, data) {
      try {
        const validar = await axios.get(`/auth/verify-registro/${data.token}/${data.rol}`, state.header);
        if (validar.data.estado) {
          return true
        } else {
          return false
        }
      } catch (error) {
        this._vm.$toasted.show(
          `Se produjo un error al validar token. ${error}`,
          {
            theme: "toasted-primary",
            position: "top-center",
            icon: 'fa-exclamation-triangle',
            type: "error",
            duration: 5000,
          }
        );
      }
    },
     /**
     * @description accion valida el token del registro de usuario
     * @param {*} param0 
     */
      async reenviarToken({ commit, state }, data) {
        try {
          const validar = await axios.post(`/auth/mandar-codigo`, data, state.header);
          if (validar.data.estado) {
            return validar.data
          } else {
            return validar.data
          }
        } catch (error) {
          this._vm.$toasted.show(
            `Se produjo un error al validar token. ${error}`,
            {
              theme: "toasted-primary",
              position: "top-center",
              icon: 'fa-exclamation-triangle',
              type: "error",
              duration: 5000,
            }
          );
        }
      },
    authLogout({ commit }) {
      localStorage.removeItem('user-session');
      sessionStorage.removeItem('user-session');
      commit('setAuth', ''); 
    }
  },
  getters: {
    authActive(state) {
      return (state.auth_token !== '') ? true : false
    }
  }
}