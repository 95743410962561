import axios from 'axios'
import Auth from '../Auth/Auth'

export default {
    namespaced: true,
    state: {
        preguntas_opciones: [],
        save_preguntas_opciones: '',
        pregunta_libre: '',
        save_pregunta_libre: '',
        verifyPagina: {},
        checkpoint: {}
    },
    mutations: {
        setPreguntasOpciones(state, payload) {
            state.preguntas_opciones = payload;
        },

        setSavePreguntasOpciones(state, payload) {
            state.save_preguntas_opciones = payload;
        },

        setPreguntaLibre(state, payload) {
            state.pregunta_libre = payload;
        },

        setSavePreguntaLibre(state, payload) {
            state.save_pregunta_libre = payload;
        },

        setSaveVerifyPregunta(state, payload) {
            state.verifyPagina = payload;
        },

        setCheckpoint(state, payload) {
            state.checkpoint = payload;
        },
    },
    actions: {
        async actionObtenerPreguntasOpciones({ commit }, data) {

            try {
                const { encuenta_id, pagina } = data;
                const api_server = await axios.get(`/encuenta/obtener-preguntas-alternativas/${encuenta_id}/${pagina}`, Auth.state.header);
                commit('setPreguntasOpciones', api_server.data.data);
            } catch (error) {
                console.log("error", error.request.status);
                if (error.request.status == 401) {
                    window.location.href = '/'
                }
            }


        },

        async actionSavePreguntasOpciones({ commit }, data) {
            const api_server = await axios.post(`/encuenta/save-preguntas-alternativas`, data, Auth.state.header);
            commit('setSavePreguntasOpciones', api_server.data);
        },

        async actionObtenerPreguntaTextoLibre({ commit }, data) {
            try {
                const { encuenta_id } = data;
                const api_server = await axios.get(`/encuenta/obtener-pregunta-texto-libre/${encuenta_id}`, Auth.state.header);
                commit('setPreguntaLibre', api_server.data.data);
            } catch (error) {
                console.log("error", error.request.status);
                if (error.request.status == 401) {
                    window.location.href = '/'
                }
            }

        },

        async actionSavePreguntaTextoLibre({ commit }, data) {
            const api_server = await axios.post(`/encuenta/save-pregunta-texto-libre`, data, Auth.state.header);
            commit('setSavePreguntaLibre', api_server.data);
        },

        async actionVerificarPreguntas({ commit }, data) {

            try {
                
                const { encuenta_id, pagina } = data;
                const api_server = await axios.get(`/encuenta/verificar-preguntas-pagina/${encuenta_id}/${pagina}`, Auth.state.header);
                if (api_server.status == 200) {
                    commit('setSaveVerifyPregunta', api_server.data.data);
                    return true
                  } else {
                    return false
                  }
                
            } catch (error) {
                console.log("error", error.request.status);
                if (error.request.status == 401) {
                    window.location.href = '/'
                }
            }


        },
        async actionVerificarCheckpoint({ commit }, data) {

            try {
               
                const api_server = await axios.post(`/registro-formulario/verificar-check-point`, data, Auth.state.header);
                if (api_server.status == 200) {
                    commit('setCheckpoint', api_server.data);
                    return true
                  } else {
                    return false
                  }
            } catch (error) {
                console.log("error", error.request.status);
                if (error.request.status == 401) {
                    window.location.href = '/'
                }
            }


        },
    },
    getters: {
        gettPreguntas(state) {
            const { preguntas } = state.preguntas_opciones;
            return preguntas;
        },
        gettOpciones(state) {
            const { opciones } = state.preguntas_opciones;
            return opciones;
        },
        gettPreguntaTextoLibre(state) {
            const { pregunta } = state.pregunta_libre;
            return pregunta;
        },
    },
}