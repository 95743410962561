<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",

  data() {
    return {};
  },

  mounted() {
    this.authVerify();
  },

  methods: {
    ...mapActions("Auth", ["authDomain", "authVerify", "authLogout"]),
  },
};
</script>
